// socialLinks.js
import { ReactComponent as LinkedInIcon } from '../assets/icons/linkedin.svg';
import { ReactComponent as InstagramIcon } from '../assets/icons/instagram.svg';
import { ReactComponent as GitHubIcon } from '../assets/icons/github.svg';
import { ReactComponent as ArenaIcon } from '../assets/icons/arena.svg';

const socialLinks = [
    {
        id: 1,
        name: "LinkedIn",
        url: "https://www.linkedin.com/in/mariasupix/",
        icon: <LinkedInIcon />,
    },
    {
        id: 2,
        name: "Instagram",
        url: "https://www.instagram.com/mariassupix/",
        icon: <InstagramIcon />,
    },
    {
        id: 3,
        name: "GitHub",
        url: "https://github.com/ssupix",
        icon: <GitHubIcon />,
    },
    {
        id: 4,
        name: "Arena",
        url: "https://www.are.na/maria-supix/",
        icon: <ArenaIcon />,
    },
];

export default socialLinks;