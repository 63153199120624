import React from "react";
import socialLinks from "../data/socialLinks"; 

const Footer = () => {

        const handleCopyEmail = (e) => {
            e.preventDefault(); 
            const email = "hello@mariasupix.com";
        
            // Copy the email to clipboard
            navigator.clipboard.writeText(email).then(() => {
                alert("Email copied to clipboard!");
            }).catch((err) => {
                console.error("Failed to copy text: ", err);
            });
        };


    return (
        <footer>
            <div className="social-links">
                {socialLinks.map((link) => (
                    <a
                        key={link.id}
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label={link.name}
                        >
                        {link.icon}
                    </a>
                ))}
            </div>
            <a href="#" onClick={handleCopyEmail} className="email-link">hello@mariasupix.com</a>
        </footer>
    );
};

export default Footer;