import React from "react";
import './style.css';
import Buttons from "./components/CTAButtons";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Graphic from "./assets/supixpink.svg";

function App() {
  return (
    <div className="App">
      {/* Other Components */}
      <Header />
      <Buttons />
      <Footer />
      <div className="graphic">
          <img src={Graphic} alt="Ssupix" />
      </div>
    </div>
  );
}

export default App;