import React from "react";
import cvFile from "../assets/maria-CV.pdf"; // Update the path
import starIcon from "../assets/icons/star.svg"; // Update the path

const CTAButtons = () => {
  // Function to handle CV download
    const handleDownload = () => {
        window.open(cvFile, "_blank");
    };

    // Function to open portfolio site
    const openPortfolio = () => {
        window.open("https://mariasupix.com", "_blank"); // Replace with your portfolio URL
    };

    return (
        <div className="cta-buttons">
        {/* Portfolio Button */}
        <button className="portfolio-button" onClick={openPortfolio}>
            PORTFOLIO SITE
            <span className="icon">
                <img src={starIcon} alt="star" />
            </span>
        </button>

        {/* CV Download Button */}
        <button className="cv-button" onClick={handleDownload}>
            DOWNLOAD CV
        </button>
        </div>
    );
};

export default CTAButtons;