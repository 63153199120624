import React from 'react';
import logoImage from '../assets/logo.svg'; // Path to your logo
import bannerImage from '../assets/banner.png'; // Path to your banner image

const Header = () => {
    return (
        <header className="header">
            <div className="logo">
                <img src={logoImage} alt="Logo" />
            </div>

            <div className="heading">
                <h1 className="name">Maria Supikhanova</h1>
                <p className="tagline">Creating meaningful experiences online.</p>
            </div>

            <div className="banner">
                <img src={bannerImage} alt="Banner" className="" />
            </div>

            <p className="description">
                *Interactive web developer and product designer.
            </p>
        </header>
    );
};

export default Header;